/* --------------------------------------------------------
[Main Stylesheet ]
    Name         :
    Version      :  1.0
    Author       :
--------------------------------------------------------
   TABLE OF CONTENTS:
========================================================
1 ) Google or local Font Import
2 ) Helping Classes
--------------------------------------------------------*/
/*      Reset CSS                 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video,
select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  line-height: 1;
}

a:focus,
a:hover,
a:active,
button,
a {
  outline: none;
  text-decoration: none;
}

.btn {
  box-shadow: none !important;
}

.img-fluid {
  max-width: 100%;
  display: block;
  height: auto;
}

.position-relative {
  position: relative;
}

/*************************************************/
/*      1 ) Google or local Font Import          */
/*************************************************/
/* font converted using font-converter.net. thank you! */
body,
html {
  height: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  font-family: 'Spartan', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

/*************************************/
/*      2 ) Helping Classes          */
/*************************************/
.ff-spartan {
  font-family: 'Spartan';
}

.ff-poppins {
  font-family: 'Poppins';
}

.bg-green {
  background: #62c68a;
}

.text-white {
  color: #fff;
}

.fr-container {
  width: 100%;
  max-width: 1440px;
  max-width: 1144px;
  max-width: 1300px;
  margin: 0px auto;
  padding: 0px 15px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cp {
  cursor: pointer;
}

.pe-none {
  pointer-events: none;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.border-0 {
  border: none !important;
}

.border-1 {
  border: 1px solid #e5e5e5;
}

hr {
  border: none;
  border-top: 1px solid #e5e5e5;
}

/* p */
.p-40 {
  padding: 40px;
}

.px-30 {
  padding: 0px 30px;
}

.px-15 {
  padding: 0px 15px;
}

.py-30 {
  padding: 30px 0px;
}


.p-py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-110 {
  padding-top: 110px;
}

.m-my-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.m-my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-120 {
  margin-top: 120px !important;
}

/* TEXT */
.pi {
  font-size: 14px;
}

.f-18 {
  font-size: 18px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-12 {
  font-size: 12px !important;
}

.p-text-success,
.text-completed,
.text-success {
  color: #62c68a;
}

.text-warning,
.text-pending {
  color: #f3d25c;
}

.text-info,
.text-progress {
  color: #00a6ff;
}

.text-danger,
.text-dispute {
  color: #ff6060;
}

/* extra */
.h-border-l,
.h-border-auto {
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: center;
  color: #0b252e;
}

h3.h-border-l,
h3.h-border-auto {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.95;
}


.h-border-l::after,
.h-border-auto::after {
  content: "";
  display: block;
  width: 73px;
  height: 3px;
  background-color: #62c68a;
}

.h-border-auto::after {
  margin: 10px auto;
}

.text-muted {
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #969faa;
}

.p-text-success {
  font-size: 16px;
  font-weight: 500;
  color: #62c68a;
  text-decoration: none;
}

/* Loader */
.p-datatable-loading-icon {
  color: #62c68a !important;
}

.sm-loader {
  padding: 140px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  left: 0px;
  top: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-text {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #707375;
}

.p-toast-top-right {
  z-index: 1045 !important;
}

.mb-apps a+a>img,
.app-links a+a>img {
  max-width: 145px;
}

.mb-apps img,
.app-links img {
  max-width: 135px;
}

/*************************************/
/*      3 )  Components              */
/*************************************/
.site-card {
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  margin: 10px 0px;
}

.site-card .site-card-header {
  padding: 30px 30px 0px;
}

.site-card-header h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;
}

/*
   01
   PRIMENG
   Buttons
*/
.p-button {
  padding: 0.8rem 1rem;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px !important;
  color: #ffffff;
  background: #62c68a;
  border: 1px solid #62c68a;
  box-shadow: none !important;
  outline: none !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.p-button-label {
  transition: none !important;
}

.p-button.p-button-success,
.p-buttonset.p-button-success>.p-button,
.p-splitbutton.p-button-success>.p-button {
  color: #ffffff;
  background: #62c68a;
  border: 1px solid #62c68a;
}

.p-button.p-confirm-dialog-reject {
  color: #ffffff;
  background: #e91e63;
  border: 1px solid #e91e63;
}

/* Hover */
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success>.p-button:enabled:hover,
.p-splitbutton.p-button-success>.p-button:enabled:hover {
  color: #ffffff;
  background: #5bd88d;
  border: 1px solid #5bd88d;
}

.p-button.p-confirm-dialog-reject:enabled:hover {
  color: #ffffff;
  background: #c61a54;
  border: 1px solid #c61a54;
}



/* Normal Buttons */
.btn {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.btn.btn-lg {
  width: 358px !important;
  height: 46px !important;
}

.btn-md {
  width: 144px;
  height: 43px;
}

.btn-md-2 {
  width: 158px;
  height: 38px;
  line-height: 0px;
}

.btn-sm {
  font-size: 13px;
  min-width: 116px;
  width: 116px;
  height: 31px;
}

.btn-xs {
  width: 80px;
  height: 38px;
}

.btn-xss {
  font-size: 12px;
  width: 60px;
  height: 23px;
}

.btn-5-15-12-90,
.btn-5-15-12 {
  font-size: 12px;
  padding: 5px 15px;
}

.btn-5-15-12-90 {
  min-width: 90px;
}

.btn-5-5-12 {
  font-size: 12px;
  padding: 5px;
}


/* Button Success */
.btn.btn-reviewed,
.btn.btn-completed,
.btn.btn-success {
  background-color: #ddf6e6;
  color: #62c68a;
}

.p-button.p-button-outlined,
.btn.btn-completed-outline,
.btn.btn-reviewed-outline,
.btn.btn-success-outline {
  border: 1px solid #62c68a;
  color: #62c68a;
  background: transparent;
}

/* hover */
.p-button.p-button-outlined:enabled:hover,
.btn.btn-success-outline:hover,
.btn.btn-success-outline:focus,
.btn.btn-completed-outline:hover,
.btn.btn-completed-outline:focus,
.btn.btn-reviewed-outline:hover,
.btn.btn-reviewed-outline:focus,
.btn.btn-success.active,
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-completed.active,
.btn.btn-completed:hover,
.btn.btn-completed:focus,
.btn.btn-reviewed.active,
.btn.btn-reviewed:hover,
.btn.btn-reviewed:focus {
  background-color: #62c68a;
  color: #ffffff;
}

/* Button Warning */
.btn.btn-warning,
.btn.btn-pending,
.btn.btn-requested {
  background-color: #f3d25c30;
  color: #f3d25c;
}

.btn.btn-pending-outline,
.btn.btn-requested-outline,
.btn.btn-warning-outline {
  border: 1px solid #f3d25c;
  color: #f3d25c;
  background: transparent;
}

/* Hov */
.btn.btn-requested-outline:hover,
.btn.btn-requested-outline:focus,
.btn.btn-pending-outline:hover,
.btn.btn-pending-outline:focus,
.btn.btn-pending.active,
.btn.btn-pending:hover,
.btn.btn-pending:focus,
.btn.btn-warning-outline:hover,
.btn.btn-warning-outline:focus,
.btn.btn-warning.active,
.btn.btn-warning:hover,
.btn.btn-warning:focus,
.btn.btn-requested.active,
.btn.btn-requested:hover,
.btn.btn-requested:focus {
  background-color: #f3d25c;
  color: #ffffff;
}

/* Info */
.btn.btn-info,
.btn.btn-progress {
  background-color: #00a6ff30;
  color: #00a6ff;
}

.btn.btn-progress-outline,
.btn.btn-info-outline {
  border: 1px solid #00a6ff;
  color: #00a6ff;
  background: transparent;
}

/* Hov */
.btn.btn-progress-outline:hover,
.btn.btn-progress-outline:focus,
.btn.btn-progress.active,
.btn.btn-progress:hover,
.btn.btn-progress:focus,
.btn.btn-info-outline:hover,
.btn.btn-info-outline:focus,
.btn.btn-info.active,
.btn.btn-info:hover,
.btn.btn-info:focus {
  background-color: #00a6ff;
  color: #ffffff;
}


.btn.btn-disputed,
.btn.btn-declined,
.btn.btn-danger,
.btn.btn-cancelled {
  background-color: #ff606030;
  color: #ff6060;
}

.btn.btn-dispute-outline,
.btn.btn-info-outline,
.btn.btn-cancelled-outline,
.btn.btn-declined-outline {
  border: 1px solid #ff6060;
  color: #ff6060;
  background: transparent;
}

/* Hov */
.btn.btn-cancelled-outline:hover,
.btn.btn-cancelled-outline:focus,
.btn.btn-declined-outline:hover,
.btn.btn-declined-outline:focus,
.btn.btn-cancelled.active,
.btn.btn-cancelled:hover,
.btn.btn-cancelled:focus,
.btn.btn-declined.active,
.btn.btn-declined:hover,
.btn.btn-declined:focus,
.btn.btn-danger-outline:hover,
.btn.btn-danger-outline:focus,
.btn.btn-danger.active,
.btn.btn-danger:hover,
.btn.btn-danger:focus,
.btn.btn-disputed.active,
.btn.btn-disputed:hover,
.btn.btn-disputed:focus {
  background-color: #ff6060;
  color: #ffffff;
}


/* btn-secondary */
.btn-secondary {
  border-radius: 6px;
  background-color: #f3f3f3;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;
  padding: 10px 20px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hover */
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #62c68a;
  color: #ffffff;
}



/*
   02
   PRIMENG
   SITE INPUTS
*/
label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  text-align: left;
  color: #0b252e;
}

.login-input {
  height: 45px;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  text-align: left;
  color: #0b252e;
}

.login-input::placeholder {
  color: #d1d1d1;
}

.p-dropdown,
.site-input {
  width: 100%;
  min-height: 47px;
  padding: 5px 10px;
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #f7f9fc;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.19px;
  text-align: left;
  color: #0b252e;
}

.site-input::placeholder {
  color: #969faa;
}

.site-input:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #62c68a45;
  border-color: #62c68a;
}

.ng-valid[required],
.ng-valid.required,
.ng-valid input,
.p-inputtext:enabled:hover {
  border-color: #62c68a;
}

/* icons */
.p-input-icon-left img {
  position: absolute;
  left: 10px;
  top: 12px;
  z-index: 1;
}

.p-input-icon-right img {
  position: absolute;
  right: 10px;
  top: 12px;
}


.p-input-icon-left .p-inputtext {
  padding-left: 2.5rem !important;
}

.p-input-icon-right .p-inputtext {
  padding-right: 2.5rem !important;
}

.p-input-icon-right>i:last-of-type,
.p-input-icon-left>i,
.p-input-icon-right>i {
  color: #d1d1d1;
}


/*
   03
   SITE
   Search box    
*/
.sm-search {
  position: relative;
}

.sm-search input {
  width: 100%;
  height: 43px;
  padding: 10px 15px;
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #f6f8f9;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;

  box-shadow: none !important;
  outline: none !important;
}

.sm-search input::placeholder {
  color: #0b252e;
}

/* icon */
.sm-search.with-left-icon>input {
  padding-left: 45px;
}

.sm-search.with-right-icon>input {
  padding-right: 45px;
}

.sm-search.with-left-icon .i {
  position: absolute;
  left: 10px;
  top: 12px;
}

.sm-search.with-right-icon i {
  position: absolute;
  right: 10px;
  top: 13px;
}


/*
   04
   SITE
   bredcrumb
*/
.bredcrumb-wrap p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969faa;
}

.bredcrumb-list {
  display: flex;
  align-items: center;
}

.bredcrumb-list h3 {
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;
}

.bredcrumb-list a {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;
  text-decoration: none;
}

.bredcrumb-list a:hover {
  color: #62c68a;
}

.bredcrumb-list .pi {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969faa;
  padding: 0px 7px;
}

/*
   05
   PrimeNG
   Tabs
*/
.site-table .p-tabview-nav {
  display: none;
}

.site-table .p-tabview-panels {
  padding: 0px;
}


/*
   06
   PrimeNG
   Table
*/
.site-table {
  border-radius: 6px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  margin: 10px 0px;
}

.site-table-header {
  padding: 30px 30px 0px;
}

.site-table-header h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;
}

/* Body */
.p-datatable-resizable>.p-datatable-wrapper {
  min-height: 300px;
}

.p-datatable .p-datatable-thead>tr>th {
  background: none;
  transition: none;
  border: none;
  padding: 1rem 1rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  text-align: left;
  color: #0b252e;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  text-align: left;
  color: #0b252e;
  border-color: #f3f3f3;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(odd) {
  background: #fbfbfb;
}

.tbl-action-btn a {
  opacity: 0.7;
}

.tbl-action-btn a:hover {
  opacity: 1;
}

/* Pagination */
.p-datatable .p-paginator-bottom {
  position: relative;
  border: none;
  border-top: 1px solid #e5e5e5;
  justify-content: flex-end;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
  display: none;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  width: 36px;
  height: 36px;
  margin: 0;
  padding: 8px 16px;
  border-radius: 6px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #62c68a;
  border-color: #62c68a;
  color: #ffffff;
}

/* buttons */
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: #f6f8f9;
}


/*
  *
   08
   PrimeNG
   Menu
  *
*/
.swap-card .p-accordion-header,
.bulkaction-menu .p-menu .p-submenu-header,
.tp-dropdown .p-menu .p-submenu-header {
  display: none;
}


.swap-card .p-accordion .p-accordion-content {
  padding: 0px;
  border: none;
}

/* Menu */
.bulkaction-menu .p-menu {
  width: auto;
}


/*
  *
   08
   PrimeNG
   Dropdown
  *
*/
.site-sorting-filter .p-dropdown,
.p-paginator .p-dropdown {
  border: none !important;
  box-shadow: none !important;
}

.site-sorting-filter label {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969faa;
}

.site-sorting-filter .p-inputtext {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b252e;
  padding-left: 0px;
}

.site-sorting-filter .p-dropdown .p-dropdown-trigger {
  color: #0b252e;
  width: auto;
}

.site-sorting-filter .p-dropdown .p-dropdown-trigger .pi {
  font-size: 12px;
}

/*
  *
   08
   PrimeNG
   Ratings
  *
*/
.p-rating .p-rating-icon {
  font-size: 14px;
  color: #e5e5e5;
}

/*
  *
   09
   PrimeNG
   ScrollBar
  *
*/
.p-scrollpanel .p-scrollpanel-bar {
  background: #f3f3f3;
  border-radius: 6px;
  opacity: 1;
  transition: background-color .2s;
}

.p-scrollpanel .p-scrollpanel-bar:hover {
  background: #e2e2e2;
}


/* Media Query */
@media only screen and (max-width : 667px) {
  .sw-service-content {
    font-size: 11px !important;
  }

  .regular-text,
  .carpenter-content p,
  .how-work--content p,
  .download-app-section p,
  .keep-up-section p,
  .provide-feedback p,
  .referrals-work p,
  .p-accordion .p-accordion-header .p-accordion-header-link,
  .p-text-success,
  .header-section p {
    font-size: 14px !important;
  }
}

@media only screen and (max-width : 520px) {

  .download-app-section h3,
  .h-border-l,
  .h-border-auto {
    font-size: 20px !important;
  }

  .p-py-70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pt-110 {
    padding-top: 30px;
  }

}
.chat-list_scrollbar .p-scrollpanel-bar-y{
  right: -355px !important;
}