@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?pvlum1');
  src:  url('fonts/icomoon.eot?pvlum1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?pvlum1') format('truetype'),
    url('fonts/icomoon.woff?pvlum1') format('woff'),
    url('fonts/icomoon.svg?pvlum1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sm-inline-logo-green:before {
  content: "\e924";
  color: #62c68a;
}
.icon-logo:before {
  content: "\e922";
  color: #62c68a;
}
.icon-connect:before {
  content: "\e920";
}
.icon-help:before {
  content: "\e91f";
}
.icon-Star_light:before {
  content: "\e928";
}
.icon-Phone_light:before {
  content: "\e929";
}
.icon-add-user:before {
  content: "\e923";
}
.icon-Iconly_Curved_Login:before {
  content: "\e921";
}
.icon-Icon-feather-file-text:before {
  content: "\e91a";
}
.icon-group-44:before {
  content: "\e91b";
}
.icon-membership-card:before {
  content: "\e91c";
}
.icon-icon-new-users:before {
  content: "\e91d";
}
.icon-doller-sign:before {
  content: "\e91e";
}
.icon-sm-inline-logo:before {
  content: "\e919";
  color: #fff;
}
.icon-swap-betwwen:before {
  content: "\e918";
}
.icon-report-bug:before {
  content: "\e916";
}
.icon-send-feedback:before {
  content: "\e917";
}
.icon-search-sm:before {
  content: "\e914";
  color: #d1d1d1;
}
.icon-enter-arrow:before {
  content: "\e915";
}
.icon-terms:before {
  content: "\e912";
  color: #62c68a;
}
.icon-safety:before {
  content: "\e913";
  color: #62c68a;
}
.icon-ic-my-business:before {
  content: "\e905";
}
.icon-ic-reviews:before {
  content: "\e907";
}
.icon-facebook-app-symbol:before {
  content: "\e910";
}
.icon-ic-instagram:before {
  content: "\e911";
}
.icon-icon-chats:before {
  content: "\e904";
}
.icon-icon-my-swaps:before {
  content: "\e906";
}
.icon-ic-membership:before {
  content: "\e90e";
}
.icon-icon-personal-info:before {
  content: "\e90f";
  color: #62c68a;
}
.icon-icon-edit:before {
  content: "\e900";
}
.icon-icon-delete:before {
  content: "\e903";
}
.icon-icon-chrome .path1:before {
  content: "\e908";
  color: rgb(244, 67, 54);
}
.icon-icon-chrome .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(33, 150, 243);
}
.icon-icon-chrome .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(76, 175, 80);
}
.icon-icon-chrome .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 193, 7);
}
.icon-icon-apple:before {
  content: "\e90c";
}
.icon-icon-android:before {
  content: "\e90d";
  color: #9ebe37;
}
.icon-eye-slash:before {
  content: "\e901";
  color: #707375;
}
.icon-eye:before {
  content: "\e902";
  color: #707375;
}
.icon-gift-line:before {
  content: "\e902a";
  color: #707375;
}
